/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

// ScrollAnim
function scrollAnim (target) {
    $(target).animatescroll({
        scrollSpeed: 800,
        easing: 'easeOutExpo',
        padding: 115,
    });
}

// Acessibilidade
(function () {
    // Alto contraste
    var Contrast = {
        storage: 'contrastState',
        cssClass: 'Contrast',
        currentState: null,
        check: checkContrast,
        getState: getContrastState,
        setState: setContrastState,
        toogle: toogleContrast,
        updateView: updateViewContrast
    };

    window.toggleContrast = function () { Contrast.toogle(); };

    Contrast.check();

    function checkContrast() {
        this.updateView();
    }

    function getContrastState() {
        return localStorage.getItem(this.storage) === 'true';
    }

    function setContrastState(state) {
        localStorage.setItem(this.storage, '' + state);
        this.currentState = state;
        this.updateView();
    }

    function updateViewContrast() {
        var body = document.body;

        if (this.currentState === null)
            this.currentState = this.getState();

        if (this.currentState)
            body.classList.add(this.cssClass);
        else
            body.classList.remove(this.cssClass);
    }

    function toogleContrast() {
        this.setState(!this.currentState);
    }

    // Alterar fonte
    var Font = {
        storage: 'FontState',
        cssClass: 'Font-plus',
        currentState: null,
        check: checkFont,
        getState: getFontState,
        setState: setFontState,
        toogle: toogleFont,
        updateView: updateViewFont
    };

    window.toggleFont = function () { Font.toogle(); };

    Font.check();

    function checkFont() {
        this.updateView();
    }

    function getFontState() {
        return localStorage.getItem(this.storage) === 'true';
    }

    function setFontState(state) {
        localStorage.setItem(this.storage, '' + state);
        this.currentState = state;
        this.updateView();
    }

    function updateViewFont() {
        var body = document.body;

        if (this.currentState === null)
            this.currentState = this.getState();

        if (this.currentState)
            body.classList.add(this.cssClass);
        else
            body.classList.remove(this.cssClass);
    }

    function toogleFont() {
        this.setState(!this.currentState);
    }
})();

/***
 * GERAL
 ***/
$(function() {

    // menu
    $('.Header nav.menu > ul > li.sub-menu').hover(function() {
        $(this).toggleClass('_active');
    });

    // Navegacao
    if ($(window).width() > 992) {
        if ($('.Lista-posts._category .Row').height() >= 530) {
            $('.Lista-posts._category .Row').height(530);
            $('.Lista-posts._category .Row').after('<div class="_tac"><a href="#" class="Button more _outline _mb30">Carregar mais artigos</a></div>');
        }

        $('.Lista-posts._category .more').click(function(event) {
            event.preventDefault();
            $('.Lista-posts._category .Row').css('height', 'auto');
            $(this).hide();
        });
    }

    // search
    var defaultText = 'Buscar';
    $('.form-search > input').click(function() {
        defaultText = $(this).val();
        $(this).val('');
        $('.form-search .link').css('display', 'inline-block');
    });

    // wow
    new WOW({
        offset: 300
    }).init();

    // Magnific Popup
    $('._galeria .imagem').each(function() {
        $(this).magnificPopup({
            type: 'image',
            gallery: {
              enabled:true
            }
        });
    });
    
    $('._galeria .video').each(function() {
        $(this).magnificPopup({
            type: 'iframe',
            gallery: {
              enabled:true
            }
        });
    });

    // Scroll
    $('.scroll').on('click', function(event) {
        var data   = $(this).data('target');
        var href   = $(this).attr('href');
        var target = (data !== undefined) ? data : href;

        event.preventDefault();
        
        scrollAnim(target);
    });

    
    // tirando autocomplete
    $('form').attr('autocomplete', 'off').attr('autosuggest', 'off');

    // Input masks
    $("input.cpf").mask("999.999.999-99");
    $("input.cnpj").mask("99.999.999/9999-99");
    $("input.data").mask("99/99/9999");
    $("input.cep").mask("99999-999");
    $("input.hora").mask("99:99");
    $("input.telefone").focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');
});